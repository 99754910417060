<template>
  <div class="app-container">
    <CrudTable entity="Customer" :columns="columns" :generate-summary="generateSummary" />
  </div>
</template>

<script>
import CrudTable from '@/components/crud/CrudTable';
import { booleanColumnFilter, booleanColumnFormatter } from '@/utils/filters';
export default {
  components: {
    CrudTable
  },
  data() {
    return {
      columns: [
        'name',
        'snelStartKlantCode',
        {
          field: 'publishToWebshopYN',
          filter: booleanColumnFilter(this),
          formatter: booleanColumnFormatter(this)
        }
      ]
    };
  },
  methods: {
    generateSummary({ name, snelStartKlantCode }) {
      return `${name} ${snelStartKlantCode}`;
    }
  }
};
</script>

<style scoped></style>
